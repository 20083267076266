<template>
  <v-container class="bg-gray" fluid :style="{height: '100%'}">
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snackbarColor"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row >
      <v-col cols="12" >
        <v-row class="white--text">
          <v-col cols="12" lg="6" class="mx-auto text-center">
            <v-img
              :src="require('../assets/logo.svg')"
              class="mx-auto mt-16"
              contain
              width="556"
              height="143"
            />
            <v-img
              :src="require('../assets/warning.svg')"
              class="ml-auto mr-auto mt-14 mb-5"
              contain
              width="75"
              height="66"
            />
            <p class="font-weight-bold" :style="{fontSize: '60px'}">Sitio en construcción</p>
            <v-icon color="#891409" class="mt-15" :style="{fontSize: '60px'}">mdi-email-outline</v-icon>
            <p class="text-h4 font-weight-bold">¿Tienes dudas? Contáctanos.</p>
            <p class="text-h6">Tel. 5553772823</p>
            <v-form ref="contactForm" v-model="isValidContactForm" autocomplete="on" @submit.prevent>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    placeholder="Nombre"
                    class="mr-md-5"
                    solo
                    v-model="name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    placeholder="E-Mail"
                    class="ml-md-5"
                    solo
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    placeholder="Mensaje"
                    auto-grow
                    solo
                    v-model="message"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-btn
              depressed
              color="#E0151C"
              class="white--text text-h6"
              min-width="196"
              min-height="45"
              :disabled="!isValidContactForm"
              @click="sendMail"
            >
              Enviar
            </v-btn>
            <p class="my-16">© 2020 Derechos Reservados</p>
          </v-col>
        </v-row>
        <v-divider class="mb-5 mx-10" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'LandingPage',

    data: () => ({
      isValidContactForm: false,
      name: '',
      email: '',
      message: '',
      rules: {
      required: value => !!value || "",
        email: v => /.+@.+\..+/.test(v) || "Debes introducir un correo válido."
      },
      snackbar: false,
      timeout: 5000,
      text: '',
      snackbarColor: ''
    }),

    created() {
    },

    methods: {
      async sendMail() {
        try {
          const data = {
            subject: `Solicitud de contacto IDEA23 de ${this.name} <${this.email}>`,
            message: this.message,
            recipient_list: [
              'paola.padilla@idea23.mx',
              'rodrigo.rodriguez@idea23.mx'
            ]
          }
          await axios.post(`${process.env.VUE_APP_SOWOS_API}/api/v1/idea23/contact`, data);
          this.text = 'El correo fue enviado correctamente, muy pronto nos pondremos en contacto contigo.';
          this.snackbarColor = 'success';
          this.snackbar = true;
        } catch (error) {
          this.text = 'No fue posible enviar el correo.';
          this.snackbarColor = 'error';
          this.snackbar = true;
          console.error(error);
        }
      }
    },
  }
</script>

<style scoped>
  .bg-gray {
    background-color: #B8B8B8;
    font-family: 'Montserrat', sans-serif;
  }
  .theme--light.v-divider {
    border-color: #ffffff !important; 
  }
</style>
